<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
const props = defineProps({
  data: Object
})

const { sanitize } = useUtils()
let windowWidth = ref(0)
let topicCards = sanitize(props.data.topics.data), cards = []

for (let i = 0; i < topicCards.length; i++) {
  cards.push(topicCards[i].attributes)
}

// Carousel options
const options = {
  perPage: 1,
  perMove: 1,
  pagination: false,
  type: 'slide',
  speed: 500,
  autoWidth: true,
  autoHeight: true,
  arrows: false,
  gap: '18px',
  mediaQuery: 'min',
  breakpoints: { // These are mobile first
    992: {
      arrows: true,
      pagination: true,
      perPage: 3,
      perMove: 3
    },
    1024: {
      arrows: true,
      pagination: true,
      perPage: 4,
      perMove: 4,
    },
    1400: {
      arrows: true,
      pagination: true,
      perPage: 5,
      perMove: 5
    }
  }
}

// Detection of elements needed for displaying the carousel or the slides without carousel
function enoughItems(windowWidth){
  let flag = false
  if (windowWidth >= 1400 && cards.length > 5) {
    flag=true
  } else if (windowWidth >= 1024 && windowWidth < 1400 && cards.length > 4) {
    flag=true
  } else if (windowWidth >= 992 && windowWidth < 1024 && cards.length > 3) {
    flag=true
  } else if (windowWidth < 992 && cards.length > 1) {
    flag=true
  }
  return flag
}

// Window resizing event listener
onMounted(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', function() {
    windowWidth.value = window.innerWidth
  })
})
</script>

<template>
  <div class="block card-topic">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <Splide v-if="enoughItems(windowWidth)" :options="options">
            <SplideSlide v-for="(card, index) in cards" :key="index">
              <div class="slide_item">
                <NuxtLink 
                  v-if="!!card.url"
                  :to="card.url" 
                  class="card-topic-container with-link" 
                  :style="`background-image: url('` + card.image.url + `')`"
                  >
                  <b v-if="card.textDisplayImage" class="card-title"><span class="title" v-html="card.textDisplayImage"></span><span class="d-none d-lg-inline">></span></b>
                </NuxtLink>
                <div v-else
                  class="card-topic-container" 
                  :style="`background-image: url('` + card.image.url + `')`"
                  >
                    <b v-if="card.textDisplayImage" class="card-title"><span class="title" v-html="card.textDisplayImage"></span><span class="d-none d-lg-inline">></span></b>
                </div>
              </div>
            </SplideSlide>
          </Splide>
          <div class="static-cards" v-else>
            <div v-for="(card, index) in cards" :key="index">
              <NuxtLink 
                v-if="!!card.url"
                :to="card.url" 
                class="card-topic-container with-link" 
                :style="`background-image: url('` + card.image.url + `')`"
                >
                <b v-if="card.textDisplayImage" class="card-title"><span class="title" v-html="card.textDisplayImage"></span><span class="d-none d-lg-inline">></span></b>
              </NuxtLink>
              <div v-else
                class="card-topic-container" 
                :style="`background-image: url('` + card.image.url + `')`"
                >
                  <b v-if="card.textDisplayImage" class="card-title"><span class="title" v-html="card.textDisplayImage"></span><span class="d-none d-lg-inline">></span></b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.card-topic {
  .static-cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & > div {
      // Selecting all card containers as they are columns
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    .card-topic-container{
      margin: 0 10px;
    }
  }
  .card-topic-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-decoration: none;
    color: $color-body-text;
    overflow: hidden;
    border-radius: 24px;
    border: solid 1px $color-light-gray;
    padding-bottom: 20px;
    width: 200px;
    height: 200px;
    transition: 0.3s;
    background: $color-secondary no-repeat center center;
    background-size: auto 100%;

    span{
      transition: 0.3s;
      display: inline-block;
      margin-right: 5px;
    }

    &.with-link:hover {
      background-size: auto 125%;
      -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      span.title{
        margin-right: 15px;
      }
    }

    .card-title {
      display: block;
      color: $color-secondary;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

// Adjustment for slide contents on this component
.slide_item {
  height: 100%;
  // padding: 10px;
  text-align: left;
}

.splide__pagination {
  display: block;
  position: relative;
  margin: 20px auto;
  padding: 0;
  text-align: center;
  bottom: auto;
}
</style>